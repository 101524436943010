 export const BASE = 'https://api-test.westlake.ink/exam/'
//  export const BASE = 'https://api.westlake.ink/exam/'


export const OSS = BASE + "oss/upload";

export const SUBJECT_INFO = BASE + "subjectinfo/list";
export const SUBJECT_ONE_INFO = BASE + "subjectinfo/info";
export const SUBJECT_SAVE = BASE + "subjectinfo/save";
export const SUBJECT_CLOSE = BASE + "subjectinfo/close";
export const SUBJECT_COPY = BASE +  "subjectinfo/copy";
export const SUBJECT_PUBLISH= BASE + "subjectinfo/publish";
export const SUBJECT_RECOVER= BASE + "subjectinfo/recover";
export const SUBJECT_UPDATE = BASE + "subjectinfo/update";
export const SUBJECT_GETITEMIDS = BASE + "subjectinfo/getItemIds";

export const SUBJECTITEM_INFO = BASE + "subjectitem/info";
export const SUBJECTITEM_LIST = BASE + "subjectitem/list";
export const SUBJECTITEM_CLOSE = BASE + "subjectitem/close";
export const SUBJECTITEM_RECOVER = BASE + "subjectitem/recover";
export const SUBJECTITEM_SAVE = BASE + "subjectitem/saves";
export const SUBJECTITEM_UPDATE = BASE + "subjectitem/update";

export const SUBJECTOPTION_SAVE = BASE + "subjectoption/save";
export const SUBJECTOPTION_UPDATE = BASE + "subjectoption/update";
export const SUBJECTOPTION_INFO = BASE + "subjectoption/info";

//总等级
export const SUBJECTSCORE_LIST = BASE + "subjectscore/list";
export const SUBJECTSCORE_INFO = BASE + "subjectscore/info";
export const SUBJECTSCORE_CLOSE = BASE + "subjectscore/close";
export const SUBJECTSCORE_RECOVER = BASE + "subjectscore/recover";
export const SUBJECTSCORE_SAVE = BASE + "subjectscore/save";
export const SUBJECTSCORE_UPDATE = BASE + "subjectscore/update";
//分维度
export const SUBJECTSCALE_LIST = BASE + "subjectscale/list";
export const SUBJECTSCALE_INFO = BASE + "subjectscale/info";
export const SUBJECTSCALE_CLOSE = BASE + "subjectscale/close";
export const SUBJECTSCALE_RECOVER = BASE + "subjectscale/recover";
export const SUBJECTSCALE_SAVES = BASE + "subjectscale/saves";
export const SUBJECTSCALE_UPDATE = BASE + "subjectscale/update";
//订单
export const ORDERINFO_LISTORDER = BASE + "orderinfo/listOrder";
// export const ORDERINFO_LISTOPTION = BASE + "orderinfo/listOption";
export const ORDERINFO_LISTOPTION = BASE + "orderinfo/info";
export const ORDERSTATIS_LIST = BASE + "orderstatis/list";

